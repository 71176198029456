import React, { useEffect, useState } from "react";
import "./SocialMediaShare.css";
import facebook from "../images/social_b__01.png";
import pinterest from "../images/social_b__03.png";
import twitter from "../images/social_b__02.png";
import linkedIn from "../images/social_b__04.png";
import phone from "../images/social.png";

const SocialMediaShare = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [highlighted, setHighlighted] = useState(true);

  useEffect(() => {
    const initialTimer = setTimeout(() => {
      setShowTooltip(true);
      const hideTimer = setTimeout(() => {
        setShowTooltip(false);
      }, 10000);

      return () => clearTimeout(hideTimer);
    }, 100);

    return () => clearTimeout(initialTimer);
  }, []);

  const handleDownloadClick = (e) => {
    e.preventDefault();
    const userAgent = navigator.userAgent;
    const androidLink =
      "https://play.google.com/store/apps/details?id=com.alternative.onedollar";
    const iosLink =
      "https://apps.apple.com/np/app/only1dollardesign/id6478212180";

    if (/android/i.test(userAgent)) {
      window.location.href = androidLink;
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = iosLink;
    } else {
      window.location.href = androidLink;
    }
  };

  const shareOptions = [
    {
      name: "Facebook",
      href: "http://www.facebook.com/sharer.php?u=http://only1dollardesign.com/",
      imageSrc: facebook,
    },
    {
      name: "Pinterest",
      href: "http://pinterest.com/pin/create/button/?url=http://only1dollardesign.com/&media=http://only1dollardesign.com/images/pin.jpg&description=The best espresso around might cost you more than a dollar, but we are bringing you fresh design choices, for just a dollar each.",
      imageSrc: pinterest,
    },
    {
      name: "Twitter",
      href: "http://twitter.com/share?url=http://only1dollardesign.com/&text=The best espresso around might cost you more than a dollar, but we are bringing you fresh design choices, for just a dollar each.",
      imageSrc: twitter,
    },
    {
      name: "LinkedIn",
      href: "http://www.linkedin.com/shareArticle?mini=true&url=http://only1dollardesign.com/?share",
      imageSrc: linkedIn,
    },
    {
      name: "Download",
      href: "#",
      imageSrc: phone,
      onClick: handleDownloadClick,
      title: "Click here to download our mobile app!",
    },
  ];

  return (
    <div className="container1">
      <div className={`toolkit ${showTooltip ? "show" : ""}`}>
        Click here to <br /> download our mobile app!
      </div>

      <div className="download-section">
        <a
          key="Download"
          href="#"
          onClick={handleDownloadClick}
          title="Click here to download our mobile app!"
        >
          <img
            src={phone}
            alt="Download"
            className={`socialicon ${highlighted ? "highlight" : ""}`}
          />
        </a>
      </div>

      {shareOptions.slice(0, -1).map((option) => (
        <a
          key={option.name}
          href={option.href}
          target="_blank"
          rel="noopener noreferrer"
          title={option.title || option.name}
        >
          <img src={option.imageSrc} alt={option.name} className="socialicon" />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaShare;
