import React, { useContext, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import BusySignal from "../BusySignal";
import {
  NavBtn,
  ClosePopup,
  CartBtnWrap,
  CartBtn,
  CartOptions,
} from "../StyledComponents";
import nextImg from "../../images/next.png";
import closeBtn from "../../images/closePopUp.png";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { DesignContext } from "../../App";
import { WholeContext } from "../../App";
import UtilitiesFn from "../../functions/UtilitiesFn";
import { getDesignName } from "../../utils/utils";
import prevImg from "../../images/prev.png";
import { claimDesign } from "../../functions/claimDesignUtils";

const FullDesign = () => {
  const imageRef = useRef(null);
  const designContextFn = useContext(DesignContext);
  const handleDesignChange = designContextFn.handleDesignChange;

  const designContext = useContext(WholeContext);
  const selectedDesign = designContext.state.selectedDesign;
  const designDetails = designContext.state.designDetails;
  const designCanvas = designContext.state.designCanvas;

  const cart = designContext.state.cart || [];
  const inCart = UtilitiesFn.alreadyInCart(selectedDesign, cart);
  const selectedThumb = designContext.state.selectedThumb;
  const firstDesign = designContext.state.firstDesign;
  const favorites = designContext.state.favorites || [];
  const designCategories = designContext.state.designCategories;

  const dispatch = designContext.dispatch;
  const loading = designContext.state.designLoading;

  useEffect(() => {
    if (designCanvas && imageRef.current) {
      imageRef.current.src = designCanvas.toDataURL();
    }
  }, [designCanvas]);

  const Intensity = (rgb) => {
    if (rgb[0] === "#") {
      rgb = rgb.slice(1);
      rgb = parseInt(rgb, 16);
    }
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;
    return (r + g + b) / 3;
  };

  const getTextColor = (color) => (Intensity(color) > 128 ? "#000" : "#fff");

  const designName = getDesignName(selectedDesign);

  const closePopup = () => {
    dispatch({ type: "set_selectedDesign", payload: "" });
  };

  const handleFavoriteToggle = () => {
    const imageUrl = selectedThumb;

    const isCurrentlyFavorite = favorites.some(
      (fav) => fav.design === selectedDesign
    );

    const updatedFavorites = isCurrentlyFavorite
      ? favorites.filter((fav) => fav.design !== selectedDesign)
      : [...favorites, { design: selectedDesign, thumb: imageUrl }];

    dispatch({ type: "set_favorites", payload: updatedFavorites });
    window.localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  const isFavorite = favorites.some((fav) => fav.design === selectedDesign);

  const handleAddToCart = (selectedDesign, imageUrl) => {
    return new Promise((resolve) => {
      const alreadyInCart = UtilitiesFn.alreadyInCart(selectedDesign, cart);
      if (!alreadyInCart) {
        const updatedCart = [
          ...cart,
          { design: selectedDesign, thumb: imageUrl },
        ];
        dispatch({ type: "set_cart", payload: updatedCart });
        window.localStorage.setItem("cart", JSON.stringify(updatedCart));
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const handleCart = (selectedDesign, imageUrl) => {
    handleAddToCart(selectedDesign, imageUrl).then((added) => {
      if (added) {
        dispatch({ type: "set_inCart", payload: true });
      }
    });
  };

  const handleImageLoaded = () => {
    dispatch({ type: "set_designLoading", payload: false });
  };

  const showNextDesign = () => {
    handleDesignChange("next");
  };

  const showPrevDesign = () => {
    if (!firstDesign) handleDesignChange("prev");
  };

  const designCategory = designCategories.find((category) =>
    selectedDesign.includes(category.Name)
  );

  const isComplimentaryCollection =
    designCategory?.Name === "Complimentary Collection";

  return (
    <div className="fulldesign-container">
      {designDetails === "" ? null : (
        <div className="PopupWrapper" id="popupWrapper">
          <Col
            className="popupContainer"
            xl={{ span: 10, offset: 1 }}
            lg={{ span: 10, offset: 1 }}
            md={{ span: 10, offset: 1 }}
            sm={{ span: 10, offset: 1 }}
            xs={10}
            style={{ height: "100%" }}
          >
            <div className="PopupArea" id="popupArea">
              <BusySignal className="BusySignal" show={loading} />
              <ClosePopup className="ClosePopup" onClick={closePopup}>
                <img alt="close button icon" src={closeBtn} width="20" />
              </ClosePopup>
              <NavBtn
                prev
                onClick={showPrevDesign}
                disabled={firstDesign ? "disabled" : null}
              >
                <img alt="previous icon" src={prevImg} width="30" />
              </NavBtn>
              <NavBtn onClick={showNextDesign}>
                <img alt="next icon" src={nextImg} width="30" />
              </NavBtn>
              <div className="DesignDisplay">
                <Col className="nopadding">
                  <div className="full-design-container">
                    <img
                      alt="full design"
                      ref={imageRef}
                      className="FullDesign"
                      data-name={selectedDesign}
                      onLoad={handleImageLoaded}
                      style={{ display: designCanvas ? "block" : "none" }}
                    />
                  </div>
                </Col>
                <Col className="ColorPatchCol">
                  {designDetails.DesignColors.length > 0
                    ? designDetails.DesignColors.map((color, index) => (
                        <div key={index}>
                          <div
                            className="Colorpatch"
                            style={{
                              backgroundColor: color.Color || "#ccc",
                              color: getTextColor(color.Color) || "#fff",
                            }}
                          >
                            <div>{color.ColorName}</div>
                          </div>
                        </div>
                      ))
                    : null}
                </Col>
                <Col className="DesignNameArea">
                  <div className="DesignInfo">
                    <div className="DesignInfoName">{designName}</div>
                  </div>
                  <CartOptions className="CartOptions">
                    {isComplimentaryCollection ? (
                      <CartBtnWrap
                        background="#B398CE"
                        onClick={() => claimDesign(selectedDesign)}
                      >
                        <CartBtn>
                          <span>CLAIM</span>
                          <br />
                          <div style={{ fontSize: "12px" }}>FREE</div>
                        </CartBtn>
                      </CartBtnWrap>
                    ) : (
                      <>
                        <CartBtnWrap
                          background="#B398CE"
                          onClick={() =>
                            handleCart(selectedDesign, selectedThumb)
                          }
                        >
                          <Link to={{ pathname: "/checkout" }}>
                            <CartBtn incart={inCart.toString()}>
                              <span>BUY</span>
                              <span>DESIGN</span>
                              <br />
                              <div style={{ fontSize: "18px" }}>$ 1</div>
                            </CartBtn>
                          </Link>
                        </CartBtnWrap>
                        <CartBtnWrap
                          background="#DB97DB"
                          onClick={() =>
                            handleCart(selectedDesign, selectedThumb)
                          }
                        >
                          <CartBtn incart={inCart.toString()}>
                            <div style={{ fontSize: "18px" }}> +</div>
                            <div>CART</div>
                          </CartBtn>
                        </CartBtnWrap>
                      </>
                    )}
                  </CartOptions>
                  <div className="FavoritesIcon" onClick={handleFavoriteToggle}>
                    {isFavorite ? (
                      <GoHeartFill size={20} color="purple" />
                    ) : (
                      <GoHeart size={20} />
                    )}
                  </div>
                </Col>
              </div>
            </div>
          </Col>
        </div>
      )}
    </div>
  );
};

export default FullDesign;
