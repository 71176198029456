import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div style={{ paddingTop: "3rem" }}>
     <Helmet>
        <title>Contact | Only1DollarDesign</title>
        <link rel="canonical" href="https://only1dollardesign.com/" />
      </Helmet>
      <explorug-contact
        themeColor="#b398ce"
        backgroundColor="#f5f5f5"
        textColor="#333"
        product="O1DD"
        fontFamily="'Lato', 'sans-serifs'"
        email="design@explorug.net"
      ></explorug-contact>
    </div>
  );
};

export default Contact;
