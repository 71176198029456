import React, { useContext } from "react";
import { CurrentPageContext, WholeContext } from "../App";
import "./Pagenav.css";

const Pagenav = () => {
  const currentPageContext = useContext(CurrentPageContext);
  const handlePageChange = currentPageContext.handlePageChange;

  const wholeContext = useContext(WholeContext);
  let currentPage = wholeContext.state.currentPage;
  let dispatch = wholeContext.dispatch;

  const handleClick = (direction) => {
    if (direction === "prev" && currentPage <= 0) return;
    
    dispatch({ type: "set_BusySignal", payload: true });
    handlePageChange(direction);
  };

  return (
    <div className="navigation-arrows">
      <button 
        className="prev-arrow" 
        onClick={() => handleClick("prev")} 
        disabled={currentPage <= 0} 
      >
        ❮
      </button>
      <button 
        className="next-arrow" 
        onClick={() => handleClick("next")}
      >
        ❯
      </button>
    </div>
  );
};

export default Pagenav;
