import React, { useContext, useEffect, useState } from "react";
import { WholeContext } from "../../App";
import { MdShoppingCartCheckout } from "react-icons/md";
import { BsCartCheckFill } from "react-icons/bs";
import { GoHeartFill } from "react-icons/go";
import { FaDownload } from "react-icons/fa6";
import { getDesignName } from "../../utils/utils";
import "./Favorites.css";
import UtilitiesFn from "../../functions/UtilitiesFn.js";
import { toggleFavorite } from "../../utils/favourite";
import { thumbDownload } from "../../functions/thumbDownload.js";
import BusySignal from "../../components/BusySignal.js";
import { Helmet } from "react-helmet";

const Favorites = () => {
  const wholeContext = useContext(WholeContext);
  const favorites = wholeContext.state.favorites || [];
  const cart = wholeContext.state.cart || [];
  const dispatch = wholeContext.dispatch;

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const storedFavorites =
      JSON.parse(window.localStorage.getItem("favorites")) || [];
    dispatch({ type: "set_favorites", payload: storedFavorites });
  }, [dispatch]);

  const isComplimentaryCollection = (designName) => {
    const trimmedName = designName.split("/")[1].replace(/\.ctf$/, "");
    return trimmedName === "Complimentary Collection";
  };

  const handleFavoriteToggle = (designPath, imageUrl) => {
    toggleFavorite(designPath, favorites, dispatch, imageUrl);

    const isFavorite = favorites.some((fav) => fav.design === designPath);
    const updatedFavorites = isFavorite
      ? favorites.filter((fav) => fav.design !== designPath)
      : [...favorites, { design: designPath, thumb: imageUrl }];

    dispatch({ type: "set_favorites", payload: updatedFavorites });
    window.localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  const handleAddToCart = (selectedDesign, imageUrl) => {
    return new Promise((resolve) => {
      const alreadyInCart = UtilitiesFn.alreadyInCart(selectedDesign, cart);
      if (!alreadyInCart) {
        addDesignToCart(selectedDesign, imageUrl).then(() => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  const addDesignToCart = (selectedDesign, imageUrl) => {
    return new Promise((resolve) => {
      const designCart = [...cart, { design: selectedDesign, thumb: imageUrl }];
      dispatch({ type: "set_cart", payload: designCart });
      window.localStorage.setItem("cart", JSON.stringify(designCart));
      resolve();
    });
  };

  const handleCart = (selectedDesign, imageUrl) => {
    handleAddToCart(selectedDesign, imageUrl).then((added) => {
      if (added) {
        dispatch({ type: "set_inCart", payload: true });
      }
    });
  };

  const handleDownload = (designName) => {
    setIsDownloading(true);
    thumbDownload(designName).finally(() => {
      setIsDownloading(false);
    });
  };

  return (
    <div className="favorites-container">
     <Helmet>
        <title>Favorites | Only1DollarDesign</title>
        <link rel="canonical" href="https://only1dollardesign.com/" />
      </Helmet>
      <h1 className="fav">FAVORITES</h1>
      <BusySignal show={isDownloading} />
      <div className="favorites-list">
        {favorites.length > 0 ? (
          favorites.map((design, index) => {
            const isInCart = UtilitiesFn.alreadyInCart(design.design, cart);
            const isComplimentary = isComplimentaryCollection(design.design);

            return (
              <div
                key={index}
                className="favorite-card"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div
                  className="icon-container"
                  style={{ display: hoveredIndex === index ? "flex" : "none" }}
                >
                  {isComplimentary ? (
                    <div
                      className="icon-circle cart-icon"
                      onClick={() => handleDownload(design.design)}
                    >
                      <FaDownload size={20} />
                    </div>
                  ) : (
                    <div
                      className="icon-circle cart-icon"
                      onClick={() => handleCart(design.design, design.thumb)}
                    >
                      {isInCart ? (
                        <BsCartCheckFill size={20} />
                      ) : (
                        <MdShoppingCartCheckout size={20} />
                      )}
                    </div>
                  )}
                  <div
                    className="icon-circle heart-icon"
                    onClick={() =>
                      handleFavoriteToggle(design.design, design.thumb)
                    }
                  >
                    <GoHeartFill size={20} />
                  </div>
                </div>
                <img
                  className="favorite-image"
                  src={design.thumb}
                  alt={design.design}
                />
                <div className="favorite-item-name">
                  {getDesignName(design.design)}
                </div>
              </div>
            );
          })
        ) : (
          <div className="favorites-text">Favorite list is empty.</div>
        )}
      </div>
    </div>
  );
};

export default Favorites;
