import React from "react";
import { Helmet } from "react-helmet";
import "./TermsofUse.css";

const TermsofUse = () => {

  return (
    <div className="container">
     <Helmet>
        <title>TermsofUse | Only1DollarDesign</title>
        <link rel="canonical" href="https://only1dollardesign.com/" />
      </Helmet>
      <div className="terms-box">
        <h1 className="heading-main">
          Please read the below mentioned Terms of Use before you access our
          platform.
        </h1>
        <h1 className="heading-title">Terms of Use</h1>
        <p className="paragraph-main">
          This website/Application/Services is operated/provided by{" "}
          <strong>Alternative Technology Private Limited</strong>, a private
          limited company incorporated as per the laws of Nepal (hereinafter
          referred to as <strong>“AT”</strong>).
          <br />
          These Terms of Use (“Terms”) apply to Your visit to and use of the
          Site whether through a computer or a mobile phone or any other device,
          the Service, and the Application, as well as to all information,
          recommendations, and/or services provided to You on or through the
          Site, the Service, and the Application.
          <br />
          By clicking on the “I ACCEPT” button or by using AT’s services or
          accessing the website and/or app, or by signing up, you are consenting
          to be bound by these Terms of Use. PLEASE ENSURE THAT YOU READ AND
          UNDERSTAND ALL THESE USER TERMS BEFORE YOU USE THE SITE or the App. In
          these Terms, the words “including” and “include” mean “including, but
          not limited to.” If You do not accept any of the User Terms, then
          please do not use the Site or avail any of the services being provided
          therein. YOUR AGREEMENT TO THESE USER TERMS SHALL OPERATE AS A BINDING
          AGREEMENT BETWEEN YOU AND AT IN RESPECT OF THE USE AND SERVICES OF THE
          SITE.
        </p>

        <div className="summary-section">
          <h2 className="heading-subtitle">Summary of our services</h2>
          <p className="paragraph-summary">
            Alternative Technology is a global company focused on computer
            graphics innovation for custom carpets. Our solution for custom
            carpets has significantly empowered the custom rug industry and has
            transformed the way carpets are designed, produced, and marketed.
            Our Virtual Carpet Software Suite is one of the very few global
            standards for designing and illustrating rugs to clients and
            exchanging carpet data between the manufacturer and the buyer. The
            topmost companies dealing with custom rugs worldwide, as well as
            leading rug producers in Nepal use our software.
            <br />
            We are not in control of the information made available by
            manufacturers via the Services. We cannot therefore make any
            representations, warranties, or guarantees in connection with what
            the auctioneer or Seller may offer for sale through the Services
            unless expressly mentioned otherwise.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">TERMS OF USE</h2>
          <p className="paragraph-summary">
            This Agreement provides the general terms and conditions (the “Terms
            of Use”) applicable to all services (the “Services”) offered by AT,
            to you through its Web site located at{" "}
            <a href="https://alternative.com.np/" className="link">
              https://alternative.com.np/
            </a>{" "}
            or the mobile application (both jointly referred to as the “Site”).
            By accessing or using this Site, you accept without limitations or
            qualifications to be bound by the Terms of Use. AT reserves the
            right to change the Terms of Use from time to time at its sole
            discretion. Your use of the Site will be subject to the most current
            version of the Terms of Use posted on the Site at the time of such
            use. In addition, when using particular Services or the material
            posted on this Site (“Materials”), or entering sections of the Site
            reserved strictly for members of AT, you shall be subject to any
            terms and conditions set out in policies of AT posted on the Site,
            including, without limitation, the Privacy Policy and other posted
            guidelines or rules applicable to such Services or Materials that
            may contain terms and conditions in addition to those in this
            Agreement. All such policies, guidelines or rules are hereby
            incorporated by reference into this Agreement. If you breach any of
            the Terms of Use, AT may, in addition to terminating your access to
            use this Site, limit your activity, immediately remove your item
            listings, warn other members of your actions, issue a warning,
            temporarily suspend, indefinitely suspend or terminate your
            subscription and refuse to provide services to you. This Agreement
            is void where prohibited by law, and the right to access the Site
            and Materials is revoked in such jurisdictions. AT maintains this
            Site for your use and grants you the permission to use and view the
            Site subject to these Terms of Use.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">
            Registration and member information
          </h2>
          <p className="paragraph-summary">
            Certain portions of the Site are only available to the registered
            members of AT as described in these Terms of Use (“Members”).
            Membership may be denied to persons at the sole discretion of AT.
            The services or materials available to Members may include services
            provided by AT and by third parties (“Associates”). To become a
            Member of AT you must submit a complete AT registration form. By
            submitting a registration form, you represent and warrant that (a)
            you are 18 years of age or over, (b) you are signing up as a Member
            on behalf of an entity you have identified in the registration form
            and are authorized to sign for and bind that entity; (c) information
            on the registration form is current, complete, and accurate; and (d)
            you are not a competitor or working for a competitor of AT. Member
            must update registration information to ensure that it continues to
            be current, complete, and accurate. AT reserves the right to accept
            or reject Member registrations.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Renewal and Cancellation</h2>
          <p className="paragraph-summary">
            Your subscription to the Services is for a minimum of the term
            identified in the service description above. You may terminate your
            subscription to the Services for any reason, or no reason at all,
            provided however that subscription fees or any other fee shall not
            be refundable if you cancel your subscription prior to its term.
            Service fees are subject to change upon renewal of subscription. The
            renewal of your subscription indicates the agreement to any
            amendments to the Terms of Use and service fee structure. AT, in its
            sole discretion, shall have the right to terminate your access to
            this Site at any time, for any reason, and AT shall not be obligated
            to disclose such reason to you. Termination of your access to this
            Site is sufficient notice of termination of this Agreement.
            Termination is in addition to all other legal or equitable remedies
            available to either party. The limitations and disclaimers of
            warranties, and the indemnity obligations of Members, and the
            provisions of governing law, jurisdiction and venue, shall survive
            the termination of this Agreement.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Service Fees</h2>
          <p className="paragraph-summary">
            AT, in its sole discretion, shall have the right to modify any
            portion of its fee structure for any reason, at any time without
            prior notice. Fees paid in advance are non-refundable if you cancel
            your subscription prior to its term or if AT terminates your access
            to the Site as a result of your non-compliance to the Terms of Use.
            Unless otherwise stated, all fees are quoted in United States
            Dollars. You are responsible for paying all fees associated with the
            Services along with applicable taxes.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">
            Use and protection of username and password
          </h2>
          <p className="paragraph-summary">
            You are responsible for maintaining the confidentiality of your
            account Username(s) and password(s). You are responsible for all
            uses of your account, whether or not actually or expressly
            authorized by you. If there is a breach of the confidentiality of a
            password(s) or Username(s) assigned to you, or any breach of
            security through your Member's account, then you shall notify AT
            immediately in writing and send the information to{" "}
            <a href="mailto:design@explorug.net" className="link">
              design@explorug.net
            </a>
            .
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Limited copyright license</h2>
          <p className="paragraph-summary">
            The Materials and Services on this Site, including, but not limited
            to, the compilation of all content, are protected by copyright
            and/or other intellectual property laws and any unauthorized use of
            the Materials or Services at this Site may violate such laws. Except
            as expressly provided herein, AT does not grant any express or
            implied right to you under any patents, copyrights, trademarks, or
            other intellectual property rights with respect to the Materials and
            Services. Except as expressly permitted herein, no portion of the
            Materials on this Site may be reproduced in any form or by any means
            without the prior written permission of AT.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Use of member information</h2>
          <p className="paragraph-summary">
            In order to make various features and functions of Services and
            Materials available to Members, AT may collect information about a
            Member and a Member's use of AT (“Member Information”). You
            acknowledge and agree that AT's collection and use of your Member
            Information as set forth in AT's Privacy Policy, and AT's operation
            and provision of Services, as contemplated under this Agreement, is
            not a breach of your right of privacy, publicity, or any other right
            related to protection of personal data, whether in equity, law,
            contract, or otherwise. You hereby waive any and all such claims or
            rights of action, whether foreseen or unforeseen, so long as your
            Member Information is collected, stored, and used in accordance with
            AT's Privacy Policy, or in the case of you making use of Materials
            or Services provided by AT Associates, the privacy policy of such
            Associate.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">
            Links to other internet web sites
          </h2>
          <p className="paragraph-summary">
            This Site may contain links to third-party Web sites that are not
            under the control of AT. AT makes no representations whatsoever
            about any other Web site to which you may have access through this
            Site, including the Web sites of AT Associates. When you access a
            non-AT Web site, you do so at your own risk and AT is not
            responsible for the accuracy or reliability of any information,
            data, opinions, advice, or statements made on these sites; or for
            the quality of any products or services available on such sites; or
            for any damage or loss caused or alleged to be caused through or in
            connection with use of or reliance on the content, goods, or
            services available on or through any such link, site, or resource.
            AT provides these links merely as a convenience and the inclusion of
            such links does not imply that AT endorses or accepts any
            responsibility for the content or uses of such Web sites.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">
            Member obligations and warranties
          </h2>
          <p className="paragraph-summary">
            You may use the Site in accordance with this Agreement. A Member
            shall use AT only for the direct benefit of such Member's internal
            business operations, and shall not resell project management,
            hosting or any other services on behalf of any third parties. With
            respect to information available on the Site, you may (i) view that
            information, and (ii) print that information for use in accordance
            with this Agreement, so long as those copies are distributed only to
            Member's employees and so long as all copyright, trademark, and
            other proprietary rights notices are included on those copies.
            Except as specifically permitted above, you shall not (i) reproduce
            or distribute any information available through the Site,
            electronically or otherwise. You shall not share, rent, or
            sublicense any of its rights under this Agreement to any other
            party.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Warranties and Disclaimers</h2>
          <p className="paragraph-summary">
            EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT SIGNED
            BY AUTHORIZED REPRESENTATIVES OF YOU AND AT, ALL MATERIALS AND
            SERVICES ON THIS SITE ARE PROVIDED “AS IS” AND “AS AVAILABLE”
            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
            BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR
            FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF
            NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, AT MAKES NO
            WARRANTY THAT (i) THE SERVICES AND MATERIALS WILL MEET YOUR
            REQUIREMENTS, (ii) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED,
            TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE
            OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE
            EFFECTIVE, ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS,
            SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THE SITE
            WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SOFTWARE
            OBTAINED FROM THE SITE WILL BE CORRECTED. THIS SITE COULD INCLUDE
            TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS.
            AT MAY MAKE CHANGES TO THE MATERIALS AND SERVICES AT THIS SITE,
            INCLUDING THE PRICES AND DESCRIPTIONS OF ANY SERVICES AND PRODUCTS
            LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR SERVICES
            AT THIS SITE MAY BE OUT OF DATE, AND AT MAKES NO COMMITMENT TO
            UPDATE SUCH MATERIALS OR SERVICES. AT ASSUMES NO RESPONSIBILITY FOR
            ERRORS OR OMISSIONS IN THE MATERIALS AND/OR SERVICES OR FOR
            INFORMATION, MATERIALS, OR SERVICES OF THIRD PARTIES THAT ARE
            REFERENCED BY OR LINKED TO THIS SITE, INCLUDING THOSE OF AT'S
            ASSOCIATES. REFERENCES TO OTHER COMPANIES, THEIR SERVICES AND
            PRODUCTS, ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY
            OF ANY KIND, EITHER EXPRESS OR IMPLIED. IN NO EVENT SHALL AT OR ITS
            LICENSORS OR ASSOCIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF
            ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION,
            THOSE RESULTING FROM LOSS OF USE, DATA, OR PROFITS, WHETHER OR NOT
            AT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR ON ANY
            LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS SITE
            OR OF ANY WEB SITE REFERENCED OR LINKED TO FROM THIS SITE, INCLUDING
            THOSE OF AT'S ASSOCIATES. THE USE OF THE SERVICES OR THE DOWNLOADING
            OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THE SITE IS DONE AT
            YOUR OWN DISCRETION AND RISK, AND WITH YOUR AGREEMENT THAT YOU WILL
            BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS
            OF DATA THAT RESULTS FROM SUCH ACTIVITIES. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM AT OR THROUGH OR FROM
            THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS
            OF USE. MEMBERS ACKNOWLEDGE THAT THE ALLOCATION OF RISK AND THE
            LIMITATIONS OF LIABILITIES CONTAINED IN THIS AGREEMENT IS A MATERIAL
            CONSIDERATION FOR AT ENTERING INTO THIS AGREEMENT.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Indemnity</h2>
          <p className="paragraph-summary">
            You shall, at your own expense, indemnify, defend, and hold AT and
            its shareholders, subsidiaries, affiliates, officers, employees,
            agents, co-branders, associates, suppliers, or other partners
            harmless from and against any losses, costs, damages, liabilities,
            and expenses (including reasonable attorneys' fees), arising out of
            or related to a third-party claim, action or allegation (a) related
            in any way to a transaction or dispute between you and another
            Member or between you and any third party, (b) based on or caused by
            unauthorized access to the Site through Member's account, or (c) is
            based on facts or alleged facts that would constitute a breach of
            any of Member's representations, warranties, or covenants under this
            Agreement. You shall not enter into a settlement of the foregoing
            without AT's prior written consent, and shall not incur expenses and
            fees in defending the above claims, actions, or allegations without
            prior approval from AT. By using this Site you acknowledge that AT
            has no control over the quality, safety, legality or accuracy of the
            content found on the Site. As a result, you shall release AT and its
            shareholders, subsidiaries, affiliates, officers, employees, agents,
            co-branders, associates, suppliers, or other partners from claims,
            demands and damages (actual and consequential) of every kind and
            nature, known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with your use of
            the content found on the Site.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Entire agreement</h2>
          <p className="paragraph-summary">
            This Agreement constitutes the entire agreement between you and AT
            with respect to the Site and supersedes all prior agreements or
            previous discussions (written or oral) between you and AT, subject
            to any specific agreement governing registration and/or Services
            subscribed by you through this Site. This Agreement may be modified
            only by a signed agreement, or as follows: AT may modify this
            Agreement by posting the new terms of use or terms and conditions in
            the same location where the previous terms and conditions were
            posted. If you continue to use the Materials, Services, or Site,
            then you will be considered to have accepted the modifications.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Miscellaneous Provisions</h2>
          <p className="paragraph-summary">
            AT's failure to enforce any provision of this Agreement will not be
            construed as a waiver of any provision or right. In the event that a
            portion of this Agreement is held unenforceable, the unenforceable
            portion will be construed in accordance with applicable law as
            nearly as possible to reflect the original intentions of the
            parties, and the remainder of the provisions will remain in full
            force and effect. Waiver by either party of a breach of any
            provision of this Agreement or the failure by either party to
            exercise any right hereunder shall not operate or be construed as a
            waiver of any subsequent breach of that right or as a waiver of any
            other right. AT will not be liable in any amount for failure to
            perform any obligation under this Agreement if such failure is
            caused by the occurrence of cause beyond the reasonable control of
            AT including, without limitation, outages of the Internet or
            connections thereto, communications outages, earthquake, fire,
            flood, war, or act of God; or any acts of governmental bodies or
            authorities. The parties agree that this Agreement shall be written
            in English only.
          </p>
        </div>

        <div className="summary-section">
          <h2 className="heading-subtitle">Governing law and Jurisdiction</h2>
          <p className="paragraph-summary">
            By accessing this Site, you and AT agree that the statutes and laws
            of Kathmandu Nepal, without regard to the conflicts of laws
            principles thereof, shall govern all matters relating to your access
            to, or use of, this Site and any Materials or Services. You and AT
            also agree and hereby submit to the exclusive personal jurisdiction
            and venue of the Courts of the Kathmandu with respect to such
            matters. AT makes no representation that Materials or Services
            available on this Site are appropriate or available for use in other
            locations, and accessing them from territories where their contents
            are illegal is prohibited. Those who choose to access this Site from
            other locations do so on their own risk and are responsible for
            compliance with local laws.
          </p>
        </div>

        <div className="contact-section">
          <h2 className="heading-subtitle">Contacting Us</h2>
          <p className="paragraph-contact">
            If you have any questions regarding this privacy policy, please
            contact us at:
          </p>
          <p className="contact-item">
            URL:{" "}
            <a href="https://only1dollardesign.com/" className="link">
              https://only1dollardesign.com/
            </a>
          </p>
          <p className="contact-item">
            Email:{" "}
            <a href="mailto:design@explorug.net" className="link">
              design@explorug.net
            </a>
          </p>
          <p className="contact-item">Phone: 977-1-4987977</p>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;
